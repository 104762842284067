/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import FranchiseOrderTab from "./FranchiseOrderTab";
import SaleOrderTab from "./SaleOrderTab";
import SaleOrderChildTab from "./SaleOrderChildTab";
import { SearchPincodeList, ErrorMessageFromRedux } from "../../../redux/actions/adminActions";
import { useDispatch, useSelector } from "react-redux";
import StocktransferTab from "./StocktransferTab";
import wsSend_request from "../../../Api/ws/ws_request";
import { WebSocketContext } from "../../../App";
import CommonLoader from "../../Common/CommonLoader";
import BundlesOrders from "./BundlesOrders";
import { Modal } from "react-bootstrap";

const PurchaseOrder = (props) => {
    const { RouteName } = props;
    const Dispatch = useDispatch();
    const { websocket } = useContext(WebSocketContext);
    const [ Tab, setTab ] = useState("franchise_order");
    const { selfInfo, device_id, commonMainLoader } = useSelector((state) => state.adminReducers);
    const [ PermissionStockInwardModule, setPermissionStockInwardModule ] = useState({
        open: false,
        permissionStockInward: "",
    });

    useEffect(() => {
        return () => {
            Dispatch(SearchPincodeList({}));
            Dispatch(ErrorMessageFromRedux({
                API: "",
                Error: "",
            }));
        };
    }, [ Tab ]);

    useEffect(() => {
        setPermissionStockInwardModule({
            ...PermissionStockInwardModule,
            open: true,
            permissionStockInward: "",
        });
    }, []);

     // Permission Add Stock fucntion
     const PermissionAddStock = (value) => {
        setPermissionStockInwardModule({
            ...PermissionStockInwardModule,
            open: false,
            permissionStockInward: value,
        });
    };

    // Stock inward modal permission 
    const onClose = () => {
        setPermissionStockInwardModule({
            ...PermissionStockInwardModule,
            open: false,
            permissionStockInward: "",
        });
        window.history.back();
    };

     // get all franchise list
     useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "franchise_list",
            "request" : { 
                "status" : "", 
                "limit" : 5000,
                "page_no" : 1, 
                "search" : ""
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    }, []);

    useEffect(() => {
        if(PermissionStockInwardModule?.permissionStockInward === "Others") {
            setTab("sale_order")
        } else if(PermissionStockInwardModule?.permissionStockInward === "E-Bikes,Accessories") {
            setTab("franchise_order")
        };
    }, [ PermissionStockInwardModule?.permissionStockInward ]);

    return(<section className="Main_section">
        <div className="MainHeader">
            {(selfInfo?.user?.user_type === 'regional_manager' || selfInfo?.user?.user_type === 'regional_sales_team_rm' || selfInfo?.user?.user_type === 'regional_sales_team_asm') ? (<ul className="MainHeader_Tab">
                <li className={Tab === "franchise_order" ? "active" : ""} onClick={() => setTab("franchise_order")}>Franchise Order</li>
            </ul>) : (<ul className="MainHeader_Tab">
                {PermissionStockInwardModule?.permissionStockInward === "Spares" && (<>
                    <li className={Tab === "franchise_order" ? "active" : ""} onClick={() => setTab("franchise_order")}>
                        {/* Franchise Order */} After Sales Order
                    </li>
                    <li className={Tab === "sale_order" ? "active" : ""} onClick={() => setTab("sale_order")}>
                        {/* End Customer/BDC */} Warranty Order
                    </li>
                </>)}
                {PermissionStockInwardModule?.permissionStockInward === "E-Bikes,Accessories" && (<>
                    <li className={Tab === "franchise_order" ? "active" : ""} onClick={() => setTab("franchise_order")}>Franchise Order</li>
                    <li className={Tab === "ecom_order" ? "active" : ""} onClick={() => setTab("ecom_order")}>Marketplace Orders</li>
                    <li className={Tab === "stock_transfer" ? "active" : ""} onClick={() => setTab("stock_transfer")}>Stock Transfer</li>
                </>)}
                {PermissionStockInwardModule?.permissionStockInward === "Others" && (<>
                    <li className={Tab === "ecom_order" ? "active" : ""} onClick={() => setTab("ecom_order")}>Marketplace Orders</li>
                    <li className={Tab === "stock_transfer" ? "active" : ""} onClick={() => setTab("stock_transfer")}>Stock Transfer</li>
                </>)}
                {PermissionStockInwardModule?.permissionStockInward === "Bundles" && (<>
                    <li className={Tab === "franchise_order" ? "active" : ""} onClick={() => setTab("franchise_order")}>Franchise Order</li>
                    <li className={Tab === "bundles_orders" ? "active" : ""} onClick={() => setTab("bundles_orders")}>Bundles Orders</li>
                </>)}
            </ul>)}
        </div>

        {PermissionStockInwardModule?.permissionStockInward !== "" && (<React.Fragment>
            {Tab === "franchise_order" ? (<>
                <FranchiseOrderTab 
                    PermissionStockInwardModule={PermissionStockInwardModule} 
                    setPermissionStockInwardModule={setPermissionStockInwardModule} 
                />
            </>) : Tab === "sale_order" ? (<>
                <SaleOrderTab 
                    PermissionStockInwardModule={PermissionStockInwardModule} 
                    setPermissionStockInwardModule={setPermissionStockInwardModule} 
                />
            </>) : Tab === "stock_transfer" ? (<>
                <StocktransferTab />
            </>) : Tab === "ecom_order" ? (<>
                <SaleOrderChildTab />
            </>) : (<>
                <BundlesOrders />
            </>)}
        </React.Fragment>)}
        
        {(commonMainLoader?.url === "order_add" && commonMainLoader?.loader) && (<CommonLoader />)}

        {PermissionStockInwardModule?.open && (<Modal show={PermissionStockInwardModule?.open} onHide={onClose} className='commonModal commonModalStockInward' centered>
            <Modal.Header className='border-bottom-0 bb-0 pb-0' closeButton>
                <Modal.Title className='h5'>Product Category  <span className='text-danger' >*</span></Modal.Title>
            </Modal.Header>
            <div className='p-3 btn-groups-main' id="btn-groups-main-id">
                <button className="btn" onClick={() => PermissionAddStock("E-Bikes,Accessories")}>E-Bikes + Accessories</button>
                <button 
                    className="btn"
                    onClick={() => PermissionAddStock("Spares")}
                    // disabled={true}
                >Spares/AFS</button>
                <button className="btn" onClick={() => PermissionAddStock("Bundles")}>Bundles</button>
                <button className="btn" onClick={() => PermissionAddStock("Others")}>Others...</button>
            </div>
        </Modal>)}
    </section>)
}

export default PurchaseOrder;